/* 
=========================================================
* Nuhome
=========================================================

* Product Page: https://nuhome.com.mx/
* Copyright 2022 Vainilla Dev (https://www.vainilladev.com)

* Coded by Miguel Valdes (mvaldes@vainilladev.com)

=========================================================
*/
import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module'
import { Spinner } from './components/Spinner';

// ** Lazy load app
const LazyApp = lazy(() => import('./components/App'));

const tagManagerArgs = {
  gtmId: 'GTM-NJ4CHPK'
}

TagManager.initialize(tagManagerArgs)

const app = document.getElementById('root')

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Spinner />}>
      <LazyApp />
    </Suspense>
  </React.StrictMode>,
 app
);
